import React, { useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons"
import Text from "../Text"

const Accordion = ({ visibleText, children }) => {
  const answerRef = useRef()

  const [answerHeight, setAnswerHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const toggleAnswer = () => {
    if (isOpen) {
      setAnswerHeight(0)
    } else {
      setAnswerHeight(answerRef.current.scrollHeight + "px")
    }
    setIsOpen(!isOpen)
  }

  const styles = {
    maxHeight: answerHeight
  }

  return (
    <div class="accordion">
      <div class={`accordion__inner ${isOpen ? "accordion__open" : ""}`}>
        <div class="accordion__question" onClick={toggleAnswer}>
          <Text text={visibleText} />
          <FontAwesomeIcon icon={faChevronDown} color="#545454" />
        </div>

        <div class="accordion__answer" style={styles} ref={answerRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion
